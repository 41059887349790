import { useEffect } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";

import BackendService from "services/backend";
import { isDevelopmentMode } from "utils/helpers/service";

export default function usePing() {
  const { settings, halVersion } = useCustomSelector((state) => state.app);
  const dispatch = useCustomDispatch();

  useEffect(() => {
    async function updateLatestPing() {
      if (!settings?.unitToken || isDevelopmentMode()) {
        // No pings in ci, staging or locally
        return;
      }
      const pong = await BackendService.ping({ frontendVersion: process.env.REACT_APP_VERSION ? parseInt(process.env.REACT_APP_VERSION) : 1, halVersion });
      if (pong.isSuccess()) {
        dispatch(appActions.setPing(pong.data));
      } else {
        dispatch(appActions.setPing(null));
      }
    }

    updateLatestPing();
    let interval = setInterval(updateLatestPing, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [settings, halVersion, dispatch]);
}
