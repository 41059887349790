import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";
import { basketActions } from "store/basket";

import HALService from "services/hal";

import useProducts from "./useProducts";
import { getJsonSettings } from "utils/helpers/misc";

import { AdminCustomSettings } from "types";

export default function useAppInit() {
  const { settings, halVersion } = useCustomSelector((state) => state.app);
  const { menus } = useCustomSelector((state) => state.menu);

  const { getProducts, getActiveMenus } = useProducts();

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  useEffect(() => {
    async function loadSetting() {
      if (!!process.env.REACT_APP_NO_HAL) {
        dispatch(
          appActions.setSettings({
            customerId: "test",
            unitToken: process.env.REACT_APP_TEST_UNIT_TOKEN || "",
            unitId: "test",
            terminalId: "test",
            terminalApiKey: "test",
            accountCardEnabled: true,
            paymentTerminalEnabled: true,
          })
        );
        return;
      }

      let customSettings = await HALService.getCustomSettings();
      if (customSettings.isSuccess()) {
        let data = getJsonSettings(customSettings.data) as AdminCustomSettings;
        if (!!data?.unitToken) {
          dispatch(appActions.setSettings(data));
          return;
        }
      }
      navigate("/credentials");
    }

    if (!settings) {
      loadSetting();
    }
  }, [settings, dispatch, navigate]);

  useEffect(() => {
    async function updateHalVersion() {
      let halVersionResponse = await HALService.getVersion();
      if (halVersionResponse.isSuccess()) {
        dispatch(appActions.setHalVersion(halVersionResponse.data));
      }
    }

    if (!halVersion) {
      updateHalVersion();
    }
  }, [halVersion, dispatch]);

  useEffect(() => {
    let products = getProducts(getActiveMenus(menus));
    if (products.length > 0) {
      dispatch(basketActions.loadBasketItems(products));
    }
  }, [menus, getProducts, getActiveMenus, dispatch]);

  return { isLoading: !settings };
}
