import { useEffect, useMemo, useState } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";

import { isDevelopmentMode } from "utils/helpers/service";

import { PongMessageType } from "types";

export default function useAlertUpdates() {
  const { latestPing } = useCustomSelector((state) => state.app);

  const [isOffline, setIsOffline] = useState(false);

  const lastSeenUtc = useMemo(() => latestPing?.lastSeenUtc || JSON.stringify(new Date().getUTCDate()), [latestPing]);

  const newVersionAvailable = useMemo(() => {
    if (!latestPing) {
      return false;
    }
    let pongMessages = latestPing.messages;
    if (pongMessages.find((message) => message.type === PongMessageType.NEW_VERSION_AVAILABLE)) {
      return true;
    }
    return false;
  }, [latestPing]);

  const dispatch = useCustomDispatch();

  // If pong hasn't responded in 30 seconds, handle as offline
  useEffect(() => {
    function handlePongTimeout() {
      setIsOffline(true);
    }

    let timeout = setTimeout(handlePongTimeout, 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastSeenUtc, dispatch]);

  useEffect(() => {
    setIsOffline(latestPing === null);
  }, [latestPing]);

  if (isDevelopmentMode()) {
    // Dev mode, no pings
    return { newVersionAvailable: false, isOffline: false };
  }

  return { newVersionAvailable, isOffline };
}
