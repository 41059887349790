import { useEffect } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";
import { userActions } from "store/user";
import { basketActions } from "store/basket";
import { menuActions } from "store/menu";

import Page from "components/ui/Page";
import Header from "components/ui/Header";
import ActionCards from "./ActionCards";
import HomeBackground from "./Background";
import Alert from "components/ui/Alert";
import Spinner from "components/ui/Spinner";

import BackendService from "services/backend";

import useAppInit from "hooks/useAppInit";

import { ReactComponent as CutleryIcon } from "assets/img/icons/cutlery.svg";

import "./Home.scss";

export default function Home() {
  const { isLoading } = useAppInit();
  const { settings } = useCustomSelector((state) => state.app);

  const dispatch = useCustomDispatch();

  // Clearing the session when returning to home screen
  useEffect(() => {
    dispatch(basketActions.clearBasketItems());
    dispatch(userActions.setCardNumber(null));
    dispatch(userActions.setExternalPaymentId(null));
    dispatch(userActions.setUserType(null));
    dispatch(menuActions.setMenus([]));
  }, [dispatch]);

  useEffect(() => {
    async function loadButtons(useGuestFlow: boolean) {
      if (!useGuestFlow) {
        dispatch(appActions.setButtons([]));
        return;
      }

      let response = await BackendService.getButtons();
      if (response.isSuccess()) {
        dispatch(appActions.setButtons(response.data));
      }
    }
    if (!!settings) {
      loadButtons(!!settings.paymentTerminalEnabled);
    }
  }, [settings, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <HomeBackground />
      <Page className="home-page">
        <Header className="home-header" />
        <div className="page-content">
          <ActionCards />
        </div>
        <p className="home-footer">
          Powered by Crunchorder <CutleryIcon /> Loomis Pay
        </p>
        <Alert />
      </Page>
    </>
  );
}
