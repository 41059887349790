import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { userActions } from "store/user";

import Spinner from "components/ui/Spinner";
import PaymentErrorState from "./States/Error";
import PaymentInProgressState from "./States/InProgress";

import HALService from "services/hal";

import useBasketValues from "hooks/useBasketValues";
import useAppInit from "hooks/useAppInit";
import usePaymentState from "./States/usePaymentState";

import { getPaymentReceiptHtml } from "utils/helpers/misc";
import { customInsightsEvent } from "utils/helpers/logs";
import { isDevelopmentMode } from "utils/helpers/service";

import { PaymentProcessingResponse, PaymentStatus, PaymentSuccessResult } from "types";

import "./Payment.scss";

export default function Payment() {
  const { settings } = useCustomSelector((state) => state.app);

  const [paymentId, setPaymentId] = useState(crypto.randomUUID());
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatusCode, setPaymentStatusCode] = useState<PaymentStatus | null>(null);
  const [paymentStatusName, setPaymentStatusName] = useState<string | null>(null);

  const { isCriticalError, isFailed, isCancelled } = usePaymentState(paymentStatusCode);

  const { isLoading: appIsLoading } = useAppInit();

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  const { total } = useBasketValues();

  const printParams = useMemo(() => {
    return { terminalId: settings?.terminalId || "", amount: total };
  }, [settings, total]);

  const handlePaymentStart = useCallback(
    async (id: string) => {
      setIsLoading(false);
      setPaymentStatusCode(null);
      setPaymentStatusName(null);

      let paymentResponse = await HALService.startPayment(id, isDevelopmentMode() ? 1 : total);
      if (paymentResponse.isSuccess()) {
        let successResponse: PaymentSuccessResult | undefined = paymentResponse.data.paymentResult?.successData;
        HALService.print(
          getPaymentReceiptHtml({
            ...printParams,
            paymentId: id,
            status: "GODKENDT",
            externalId: successResponse?.paymentReference,
            cardIssuer: successResponse?.cardData?.scheme,
            cardNumber: successResponse?.cardData?.maskPan,
          })
        );

        if (successResponse?.paymentReference) {
          dispatch(userActions.setExternalPaymentId(successResponse.paymentReference));
        }

        setPaymentStatusCode(paymentResponse.data.statusCode);
        navigate("/order");
      } else {
        HALService.print(getPaymentReceiptHtml({ ...printParams, paymentId: id, status: "FEJLET" }));
        if (paymentResponse.error) {
          let failedResponse = paymentResponse.error as unknown as PaymentProcessingResponse;
          setPaymentStatusCode(failedResponse?.statusCode || PaymentStatus.CODE_ERROR);
          setPaymentStatusName(failedResponse?.statusName || null);
        }
      }
    },
    [printParams, total, dispatch, navigate]
  );

  useEffect(() => {
    handlePaymentStart(paymentId);
  }, [paymentId, handlePaymentStart]);

  // Redirecting after delay on failed payment
  useEffect(() => {
    function handleFailedPaymentTimeout() {
      navigate("/");
    }

    let timer: any;
    if (isCriticalError || isFailed || isCancelled) {
      timer = setTimeout(handleFailedPaymentTimeout, 10000);
    } else if (timer) {
      clearTimeout(timer);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCriticalError, isFailed, isCancelled, navigate]);

  useEffect(() => {
    if (isCriticalError) {
      customInsightsEvent("FailedPayment", { unitId: settings?.unitId || "Ukendt", paymentId, paymentStatusCode: `${paymentStatusCode}`, paymentStatusName: `${paymentStatusName}` });
    }
  }, [isCriticalError, paymentId, paymentStatusCode, paymentStatusName, settings]);

  async function handleCancelClick() {
    setIsLoading(true);
    setPaymentStatusCode(PaymentStatus.PAYMENT_CANCELLED);
    await HALService.cancelPayment(paymentId);
  }

  if (appIsLoading) {
    return <Spinner />;
  }

  if (isCancelled || isCriticalError || isFailed) {
    return (
      <PaymentErrorState
        statusCode={paymentStatusCode}
        statusName={paymentStatusName}
        onRetryClick={() => setPaymentId(crypto.randomUUID())}
        onBackClick={(isEmployeeFlow) => (isEmployeeFlow ? navigate("/") : navigate("/menu"))}
      />
    );
  }

  return <PaymentInProgressState isLoading={isLoading} handleCancelClick={handleCancelClick} />;
}
