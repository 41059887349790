import { useCallback } from "react";
import { useCustomSelector } from "store/useStore";

import { customInsightsEvent } from "utils/helpers/logs";

import { Menu, Product } from "types";

const isAfterFromTime = ({ fromTime, currentHour, currentMinute }: { fromTime: Date; currentHour: number; currentMinute: number }) => {
  if (fromTime.getHours() > currentHour) {
    // Later hour than now
    return false;
  }
  if (fromTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return fromTime.getMinutes() <= currentMinute;
  }
  // Hour is before current one
  return true;
};

const isBeforeToTime = ({ toTime, currentHour, currentMinute }: { toTime: Date; currentHour: number; currentMinute: number }) => {
  if (toTime.getHours() <= currentHour) {
    // Before current hour
    return false;
  }
  if (toTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return toTime.getMinutes() > currentMinute;
  }
  // Hour is after current one
  return true;
};

export default function useProducts() {
  const { settings } = useCustomSelector((state) => state.app);
  const { cardNumber } = useCustomSelector((state) => state.user);

  const getActiveMenus = useCallback(
    (menus: Menu[]) => {
      let available = menus;
      const now = new Date();

      // Weekday filter
      const today = now.getDay();
      available = available.filter(({ activeWeekDays = [] }) => activeWeekDays.includes(today));

      // Time of day filter
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      available = available.filter(({ activeFrom, activeTo }) => {
        if (!activeFrom || !activeTo) {
          return true;
        }
        let fromTimestamp = new Date(activeFrom);
        let toTimestamp = new Date(activeTo);
        return isAfterFromTime({ fromTime: fromTimestamp, currentHour, currentMinute }) && isBeforeToTime({ toTime: toTimestamp, currentHour, currentMinute });
      });

      if (available.length === 0) {
        customInsightsEvent("EmptyMenusList", {
          unitId: settings?.unitId || "Ukendt",
          cardNumber: `${cardNumber}`,
          menus: `${menus.reduce((acc, curr) => acc.concat([curr.name]), [] as string[]).join(", ")}`,
        });
      }

      return available;
    },
    [settings, cardNumber]
  );

  const getProducts = useCallback(
    (menus: Menu[]) => {
      let products = menus.reduce((acc: Product[], curr) => acc.concat(curr.products), []);
      if (menus.length > 0 && products.length === 0) {
        customInsightsEvent("EmptyProductList", {
          unitId: settings?.unitId || "Ukendt",
          cardNumber: `${cardNumber}`,
          menus: `${menus.reduce((acc, curr) => acc.concat([curr.name]), [] as string[]).join(", ")}`,
        });
      }
      return products;
    },
    [settings, cardNumber]
  );

  return { getProducts, getActiveMenus };
}
